import { render, staticRenderFns } from "./inventory-card.vue?vue&type=template&id=94b09ece&scoped=true&"
import script from "./inventory-card.vue?vue&type=script&lang=js&"
export * from "./inventory-card.vue?vue&type=script&lang=js&"
import style0 from "./inventory-card.vue?vue&type=style&index=0&id=94b09ece&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94b09ece",
  null
  
)

export default component.exports