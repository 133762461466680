<template>
  <div class="item_list over-hide">
    <form action="/search">
      <van-search
        v-model="searchVal"
        placeholder="请输入关键字"
        show-action
        @input="onInput"
        @search="onSearch"
        @cancel="onCancel"
      >
        <template #action>
          <div class="item_list_search_btn">
            <div v-if="showCancel" @click="onCancel">
              取消
            </div>
            <div v-else @click="onSearch(searchVal)">
              搜索
            </div>
          </div>
        </template>
      </van-search>
    </form>
    <inventory-card
      :goods-n="storeinfo.goodsN"
      :total-inv="storeinfo.totalInv"
      :in-inv="storeinfo.inInv"
      :out-inv="storeinfo.outInv"
    />
    <van-pull-refresh
      ref="goodslist"
      v-model="refreshing"
      class="fixheight"
      @refresh="onRefresh()"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        offset="100"
        class="class_item_list"
        @load="onListLoad"
      >
        <item-card-goods-store
          v-for="(item, index) in goodsListFilted"
          :key="index"
          :goods="item"
          :store="item.store"
          :store-in="item.storeIn"
          :store-out="item.storeOut"
          class="class_item_card"
          @click="onShowDetail(item)"
        />
        <van-empty v-if="items.length === 0" description="库存为空" />
      </van-list>
    </van-pull-refresh>
    <!-- <transition name="fade">
      <van-button
        v-if="showBtn"
        class="class_deliver_btn"
        type="primary"
        @click="deliveClick"
      >
        出库
      </van-button>
    </transition> -->
    <van-button class="class_deliver_btn" type="primary" @click="deliveClick">
      出库
    </van-button>
    <van-popup
      v-model="popupshow"
      position="right"
      :style="{ height: '100%', width: '70%' }"
    >
      <popup-btn @onPpReset="onPpReset" @onPpConfirm="onPpConfirm">
        <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="option1" />
        </van-dropdown-menu>
      </popup-btn>
    </van-popup>
  </div>
</template>

<script>
import {
  List,
  NoticeBar,
  Cell,
  Search,
  Tab,
  Tabs,
  Empty,
  Popup,
  DropdownMenu,
  Button,
  PullRefresh,
  DropdownItem
} from 'vant'
import ItemCardGoods from '../../components/item-card-goods'
import ItemCardGoodsStore from '../../components/item-card-goods-store'
import PopupBtn from '../../components/PopupBtn'
import asyncwait from '@/mixins/asyncwait'
import handleNetError from '@/mixins/checklogin'
import InventoryCard from './inventory-card'
// import { throttle } from '@/utils/throttle'
// import { debounceTail } from '@/utils/debounce'
// import { debounce, throttle } from 'lodash/function'
// eslint-disable-next-line no-unused-vars
import { storeList, outstore } from '@/api/inventory'
import { mapMutations } from 'vuex'
export default {
  name: 'Inventory',
  components: {
    [List.name]: List,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [NoticeBar.name]: NoticeBar,
    [PullRefresh.name]: PullRefresh,
    [Tab.name]: Tab,
    [Empty.name]: Empty,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [ItemCardGoods.name]: ItemCardGoods,
    [ItemCardGoodsStore.name]: ItemCardGoodsStore,
    [InventoryCard.name]: InventoryCard,
    [PopupBtn.name]: PopupBtn,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  mixins: [asyncwait, handleNetError],
  data() {
    return {
      transitionName: 'van-slide-right',
      searchVal: '',
      popupshow: false,
      loading: false,
      error: false,
      finished: false,
      refreshing: false,
      showCancel: false,
      showBtn: true,
      items: [],
      value1: 0,
      filter: '',
      pageIndex: 1,
      storeinfo: { goodsN: 0, totalInv: 0, inInv: 0, outInv: 0 },
      option1: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 }
      ]
    }
  },
  computed: {
    goodsListFilted() {
      if (this.filter === '') return this.items
      else
        return this.items.filter(item => item.goods_name.includes(this.filter))
    }
  },
  created() {},
  activated() {
    console.log('inventory activated')
    this.showBtn = true
    if (this.$route.meta.isApply) {
      console.log('activated isApply onRefresh')
      this.onRefresh()
      this.$route.meta.isApply = false
    }
  },
  mounted() {
    // console.log('inventory mounted', this.$refs['goodslist'])
    // let elem = this.$refs['goodslist'].$el
    // this.throttleLoad = throttle(() => {
    //   this.scrollHander(elem)
    // }, 77)
    // this.throttleLoadS = throttle(() => {
    //   this.scrollHanderS(elem)
    // }, 277)
    // this.throttleL = debounce(() => {
    //   this.scrollHanderL()
    // }, 770)
    // elem.addEventListener('touchstart', this.throttleLoad)
    // elem.addEventListener('scroll', this.throttleLoadS)
    // elem.addEventListener('touchstart', this.throttleL)
    // elem.addEventListener('scroll', this.throttleL)
  },
  methods: {
    ...mapMutations(['mSaveGoodsList']),
    showlogin() {
      console.log('fdfds')
      this.$router.push('/login')
    },
    scrollHander(elem) {
      // console.log(
      //   `elem:${elem.scrollTop} ${elem.scrollHeight} ${elem.offsetHeight}`
      // )
      if (elem.scrollHeight !== elem.scrollTop + elem.offsetHeight)
        this.showBtn = false
      else this.showBtn = true
    },
    scrollHanderS() {
      // console.log('scrollHanderS')
      this.showBtn = false
    },
    scrollHanderL() {
      // console.log('scrollHanderL')
      this.showBtn = true
    },
    navclick() {
      this.popupshow = true
    },
    onInput() {
      this.showCancel = false
    },
    onSearch(e) {
      this.filter = e
      if (e === '') return
      this.showCancel = true
    },
    onCancel() {
      this.filter = ''
      this.searchVal = ''
      this.showCancel = false
    },
    onfilter(e) {
      this.filter = e
    },
    onPpReset() {
      this.popupshow = false
      console.log('onPpReset')
    },
    deliveClick() {
      console.log('deliveClick')
      this.$router.push({ name: 'deliveEntity' })
    },
    onPpConfirm() {
      this.popupshow = false
      console.log('onPpConfirm')
    },
    onShowDetail(item) {
      console.log('onShowDetail', item)
      this.$router.push({
        name: 'invDetailEntity',
        query: { id: `${item.goods_id}` }
      })
    },
    async onRefresh() {
      // 清空列表数据
      this.items = []
      this.finished = false
      this.refreshing = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.pageIndex = 1
      await this.onListLoad()
    },
    async onListLoad() {
      console.log('callload')
      await this.asyncWait(300)
      try {
        const res = await storeList({
          // pageIndex: this.pageIndex,
          // pageSize: 5
        })
        console.log(res)
        if (res.status !== 1) throw res
        // this.pageIndex++
        // this.items = [...this.items, ...res.data.list]
        this.items = res.data.list
        this.mSaveGoodsList(this.items)
        this.storeinfo = {
          goodsN: this.items.length,
          totalInv: res.data.storeCount,
          inInv: res.data.storeInCount,
          outInv: res.data.storeOutCount
        }
        this.loading = false
        // if (res.data.list.length < 5) {
        //   this.finished = true
        // }
        this.finished = true
        console.log('teamBuildLogList cp')
      } catch (err) {
        this.handleNetError(err)
        this.loading = false
        this.error = true
        console.log(err)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.fixheight {
  height: calc(100vh - 62px - 60px - 45px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.item_list_search_btn {
  display: flex;
}
.class_item_card {
  width: calc(100vw - 10px);
  margin: 5px;
}
.class_item_list {
  padding-bottom: 50px;
}
.class_deliver_btn {
  position: absolute;
  // right: 30px;
  // bottom: 60px;
  bottom: 0;
  z-index: 10;
  // width: 150px;
  // height: 50px;
  width: 100vw;
  // border-radius: 20px;
  opacity: 0.9;
}
.fade-enter-active {
  transition: all 0.5s 0.2s;
}
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(50px);
}
</style>
