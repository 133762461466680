<template>
  <div class="inventory_card_warp">
    <div class="inventory_card_warp_item">
      <div class="inventory_card_warp_item_main">
        {{ goodsN }}
      </div>
      <div class="inventory_card_warp_item_sub">
        商品个数(个)
      </div>
    </div>
    <div class="inventory_card_warp_item">
      <div class="inventory_card_warp_item_main">
        <span id="numi2">{{ totalInv }}</span>
      </div>
      <div class="inventory_card_warp_item_sub">
        总库存(吨)
      </div>
    </div>
    <div class="inventory_card_warp_item">
      <div class="inventory_card_warp_item_main">
        <span id="numi3">{{ inInv }}</span>
      </div>
      <div class="inventory_card_warp_item_sub">
        总入库(吨)
      </div>
    </div>
    <div class="inventory_card_warp_item">
      <div class="inventory_card_warp_item_main">
        <!-- <i-count-up :delay="1000" :end-val="outInv1" :options="options" /> -->
        <span id="numi4">{{ outInv }}</span>
      </div>
      <div class="inventory_card_warp_item_sub">
        总出库(吨)
      </div>
    </div>
  </div>
</template>
<script>
import countup from '@/mixins/mixcountup'
export default {
  name: 'InventoryCard',
  mixins: [countup],
  props: {
    goodsN: {
      type: Number,
      default: 0
    },
    totalInv: {
      type: Number,
      default: 0
    },
    inInv: {
      type: Number,
      default: 0
    },
    outInv: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      goodsN1: this.goodsN,
      totalInv1: this.totalInv,
      inInv1: this.inInv,
      outInv1: this.outInv,
      options: {
        useEasing: true,
        decimalPlaces: 2,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    }
  },
  watch: {
    totalInv(newVal, oldVal) {
      console.log('watch totalInv:', newVal, oldVal)
      this.numcountup('numi2', newVal, oldVal)
      this.totalInv1 = newVal
    },
    inInv(newVal, oldVal) {
      console.log('watch inInv:', newVal, oldVal)
      this.numcountup('numi3', newVal, oldVal)
      this.inInv1 = newVal
    },
    outInv(newVal, oldVal) {
      console.log('watch outInv:', newVal, oldVal)
      this.numcountup('numi4', newVal, oldVal)
      this.outInv1 = newVal
    }
  },
  methods: {
    update() {
      console.log('bc update')
      this.totalInv1 = this.totalInv
      this.inInv1 = this.inInv
      this.outInv1 = this.outInv
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.inventory_card_warp {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  height: 62px;
  padding-top: 10px;
  background-color: #ffffff;
}
.inventory_card_warp_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  &_main {
    font-size: @font-size-bigger;
    color: #30ae64;
  }
  &_sub {
    font-size: @font-size-small;
    color: #9a9a9a;
  }
}
</style>
