import { get, post } from '@/utils/request'

/**
 * 库存列表接口
 * @param {Sting}} data 参数
 */
export const storeList = (data = {}) => {
  return get('storeList', data)
}

/**
 * 出库接口
 * @param {Sting}} data 参数
 */
export const outstore = (data = {}) => {
  return post('outstore', data)
}

/**
 * 库存详情接口
 * @param {Sting}} data 参数
 */
export const getStoreDetail = (data = {}) => {
  return get('getStoreDetail', data)
}

/**
 * 库存详情历史接口
 * @param {Sting}} data 参数
 */
export const getStoreLogList = (data = {}) => {
  return get('getStoreLogList', data)
}
